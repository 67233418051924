import '../styles/index.scss';

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}

console.log('webpack starterkit');

import { gsap } from "gsap";
import Scrollbar from 'smooth-scrollbar';
import { changeLocale } from "./translations";

function onCursorMove(e){
  gsap.to('#custom-cursor', {duration: 0.75, ease: 'power3.out', x: e.clientX, y: e.clientY});
}

(function(){
  if(window.innerWidth > 767 ){
    gsap.set("#custom-cursor", {x: 100, y: 50});
    window.addEventListener("mousemove", onCursorMove);

    let cursorEl = document.getElementById("custom-cursor");

    function addIsHovering() {
      cursorEl.classList.add("is-hovering");
    }

    function removeIsHovering() {
      cursorEl.classList.remove("is-hovering");
    }

    removeIsHovering();

    for (var r = document.querySelectorAll("a, .hover-target"), a = r.length - 1; a >= 0; a--) {
      addMouseListeners(r[a]);
    }

    function addMouseListeners(hoverTargetEl) {
      hoverTargetEl.addEventListener("mouseover", addIsHovering);
      hoverTargetEl.addEventListener("mouseout", removeIsHovering);
    }

    const leftScrollbar = Scrollbar.init(document.querySelector('.text-content'));
    leftScrollbar.track.yAxis.element.remove();

    const rightScrollbar = Scrollbar.init(document.querySelector('.info-content'));
    rightScrollbar.track.yAxis.element.remove();
  }

  if(window.location.hash === "#en"){
    changeLocale("en");
  } else {
    changeLocale("de");
  }

  const enBtn = document.querySelector(".en-btn");
  enBtn.addEventListener("click", () => {
    changeLocale("en");
  });

  const deBtn = document.querySelector(".de-btn");
  deBtn.addEventListener("click", () => {
    changeLocale("de");
  });

})();


