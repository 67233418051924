import Polyglot from 'node-polyglot';

const deTranslations = {
  "title": "Communitas @ Kunsthaus Steffisburg",
  "aktuell": "Aktuell",
  "archiv": "Archiv",
  "medien": "Medien",
  "p0": "Das Kuratorinnen-Kollektiv ruft zur Mitgestaltung auf und setzt sich zum Ziel, das Potential des Mediums Ausstellung auszuloten und es partizipativ zu öffnen.",
  "p1": "Im Zentrum steht dabei die Frage: Wie lässt sich Gemeinschaft zeitgemäss denken?",
  "p2": "Das Kollektiv – bestehend aus Domenika Chandra, Hanna G. Diedrichs genannt Thormann und Anna Schiestl – versteht Vermittlung nicht als Ergänzung, sondern als integralen Bestandteil ihrer Ausstellungsprojekte. In experimentellen Formaten werden Verknüpfungen zwischen Positionen des zeitgenössischen Kunst- und Kulturschaffens und den individuellen Lebenswelten der Menschen hergestellt. Durch Partizipation und gemeinschaftliche Auseinandersetzung findet eine Erprobung verschiedener Formen des kollektiven Ausstellens statt."
};

const enTranslations = {
  "title": "Communitas @ Kunsthaus Steffisburg",
  "aktuell": "Currently",
  "archiv": "Archive",
  "medien": "Press",
  "p0": "The curatorial collective calls for participation and aims to fathom the medium of exhibition and to open it up to participation.",
  "p1": "Their main question is: How can a community be thought of in a contemporary way?",
  "p2": "The collective – consisting of Domenika Chandra, Hanna G. Diedrichs genannt Thormann and Anna Schiestl – considers art mediation not as a mere supplement but as an integral part of their projects. Links between positions in contemporary art and culture and people’s individual social environments are initiated in experimental setups. Through participation and dialogue, various forms of collective curating are examined."
};

const changeLocale = (loc) => {
  const polyglot = new Polyglot({
    phrases: loc === "de" ? deTranslations : enTranslations
  });

  const title =  document.querySelector(".info-content .title");
  title.textContent = polyglot.t("title");

  document.querySelector(".info-content #aktuell").textContent = polyglot.t("aktuell");
  document.querySelector(".info-content #archiv").textContent = polyglot.t("archiv");
  document.querySelector(".info-content #medien").textContent = polyglot.t("medien");

  const paragraphs = document.querySelectorAll(".text-content p");
  paragraphs.forEach((p, index) => {
    p.textContent = polyglot.t("p" + index);
  });

  document.documentElement.lang = loc;
};

export { changeLocale };